import axios from 'utils/axios';
import {
  LOGIN_ROUTE,
  GET_LOGIN_DATA_ROUTE,
  UPDATE_PASSWORD_ROUTE,
  UPDATE_PROFILE_CONTACT_ROUTE,
  UPDATE_PROFILE_CLIENT_ROUTE,
  UPDATE_CLIENT_META_DATA_ROUTE,
  ADD_CLIENT_PHONENUMBER_ROUTE,
  REMOVE_CLIENT_PHONENUMBER_ROUTE,
  REMOVE_PROFILE_IMAGE_ROUTE,
  UPDATE_EMAIL_NOTIFICATIONS_ROUTE,
  GET_RESELLER_DATA_ROUTE,
  GET_LOGIN_BACKGROUND_ROUTE,
  GET_LOGIN_FORGOT_PASSWORD_ROUTE,
  GET_LOGIN_RESET_PASSWORD_ROUTE
} from 'utils/endpoints';
import { store, setToken, setUser, setKey } from 'store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setReseller } from 'store';
import { setLoginBackground } from 'store';

export const handleBackgroundImg = async () => {
  try {
    const res = await axios.get(`${GET_LOGIN_BACKGROUND_ROUTE}`);
    if (res) {
      store.dispatch(
        setLoginBackground({
          loginBackgroundImg: res?.data
        })
      );
    }
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const handleLogin = async ({ data, setIsLoading }) => {
  try {
    const res = await axios.post(LOGIN_ROUTE, data);
    if (res.status === 200) {
      store.dispatch(
        setToken({
          token: res?.data?.result?.token
        })
      );
      store.dispatch(
        setKey({
          key: res?.data?.result?.key
        })
      );
      store.dispatch(
        setUser({
          user: res?.data?.result?.data
        })
      );

      return res;
    } else setIsLoading(false);
  } catch (error) {
    throw error;
  }
};

export const handleUpdateProfileContact = createAsyncThunk(
  'updateProfileContact',
  async ({ data, contactid, clientid, user }, { dispatch }) => {
    try {
      const res = await axios.post(
        `${UPDATE_PROFILE_CONTACT_ROUTE}/${contactid}/${clientid}`,
        data,
        {
          xApi: true
        }
      );
      if (res.data) {
        const newClientObj = res.data;
        store.dispatch(
          setUser({
            user: { ...user, ...newClientObj }
          })
        );
      }
      return res.data;
    } catch (error) {
      console.log('Error Updating contact data:', error);
      throw error;
    }
  }
);
export const handleUpdateProfileClient = createAsyncThunk(
  'updateProfileClient',
  async ({ user, data, clientid, contactid }, { dispatch }) => {
    try {
      const res = await axios.post(
        `${UPDATE_PROFILE_CLIENT_ROUTE}/${clientid}/${contactid}`,
        data,
        {
          xApi: true
        }
      );

      if (res.data) {
        const newClientObj = res?.data;
        store.dispatch(
          setUser({
            user: { ...user, ...newClientObj }
          })
        );
      }
      return res.data;
    } catch (error) {
      console.log('Error Updating client data:', error);
      throw error;
    }
  }
);
export const handleAddClientPhonenumber = createAsyncThunk(
  'AddClientPhoneNumber',
  async ({ user, data, clientid }, { dispatch }) => {
    try {
      const res = await axios.post(
        `${ADD_CLIENT_PHONENUMBER_ROUTE}/${clientid}`,
        data,
        {
          xApi: true
        }
      );

      if (res.data) {
        const newClientObj = res?.data;
        store.dispatch(
          setUser({
            user: { ...user, ...newClientObj }
          })
        );
      }
    } catch (error) {
      console.log('Error Updating client data:', error);
      throw error;
    }
  }
);
export const handleUpdateEmailNotifications = createAsyncThunk(
  'UpdateEmailNotifications',
  async ({ user, data, contactid }, { dispatch }) => {
    try {
      const res = await axios.post(
        `${UPDATE_EMAIL_NOTIFICATIONS_ROUTE}/${contactid}`,
        data,
        {
          xApi: true
        }
      );

      if (res.data) {
        const newClientObj = res?.data;
        store.dispatch(
          setUser({
            user: { ...user, ...newClientObj }
          })
        );
      }
      return res.data;
    } catch (error) {
      console.log('Error Updating Emails notification:', error);
      throw error;
    }
  }
);
export const handleRemoveClientPhonenumber = createAsyncThunk(
  'RemoveClientPhoneNumber',
  async ({ user, data, clientid }, { dispatch }) => {
    try {
      const res = await axios.post(
        `${REMOVE_CLIENT_PHONENUMBER_ROUTE}/${clientid}`,
        data,
        {
          xApi: true
        }
      );

      if (res.data) {
        const newClientObj = res?.data;
        store.dispatch(
          setUser({
            user: { ...user, ...newClientObj }
          })
        );
      }
      return res.data;
    } catch (error) {
      console.log('Error Updating client data:', error);
      throw error;
    }
  }
);

export const handleChangePassword = async ({ data, contactid }) => {
  try {
    const res = await axios.post(
      `${UPDATE_PASSWORD_ROUTE}/${contactid}`,
      data,
      {
        xApi: true
      }
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getLoginUser = async ({ setIsLoading }) => {
  try {
    setIsLoading(true);
    const res = await axios.get(GET_LOGIN_DATA_ROUTE, { xApi: true });
    if (res.status === 200) {
      if (res?.data?.client?.resellerid) {
        getReseller({ resellerid: res?.data?.client?.resellerid });
      }
      store.dispatch(
        setUser({
          user: res?.data
        })
      );
      setIsLoading(false);
    }
  } catch (error) {
    throw error;
  }
};

export const getReseller = async ({ resellerid }) => {
  try {
    const res = await axios.get(`${GET_RESELLER_DATA_ROUTE}/${resellerid}`, {
      xApi: true
    });
    if (res.status === 200) {
      store.dispatch(
        setReseller({
          reseller: res?.data
        })
      );
    }
  } catch (error) {
    throw error;
  }
};

export const handleUpdateClientMetaData = createAsyncThunk(
  'updateProfileAdditionalInfo',
  async ({ data, clientid, user }, { dispatch }) => {
    try {
      const res = await axios.post(
        `${UPDATE_CLIENT_META_DATA_ROUTE}/${clientid}`,
        data,
        {
          xApi: true
        }
      );

      if (res.data) {
        const newClientObj = res?.data;
        store.dispatch(
          setUser({
            user: { ...user, ...newClientObj }
          })
        );
      }
      return res.data;
    } catch (error) {
      console.log('Error Updating contact data:', error);
      throw error;
    }
  }
);
export const handleRemoveContactImage = createAsyncThunk(
  'RemoveContactImage',
  async ({ data, contactid, user }, { dispatch }) => {
    try {
      const res = await axios.post(
        `${REMOVE_PROFILE_IMAGE_ROUTE}/${contactid}`,
        data,
        {
          xApi: true
        }
      );

      if (res.data) {
        const newClientObj = res?.data;
        store.dispatch(
          setUser({
            user: {
              ...user,
              ...newClientObj
            }
          })
        );
      }
    } catch (error) {
      console.log('Error Updating contact data:', error);
      throw error;
    }
  }
);

export const handleForgotPassword = async ({ email }) => {
  try {
    const res = await axios.post(`${GET_LOGIN_FORGOT_PASSWORD_ROUTE}`, email);
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    throw error;
  }
};

export const handleResetPassword = async ({ data }) => {
  try {
    const res = await axios.post(`${GET_LOGIN_RESET_PASSWORD_ROUTE}`, data);
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    throw error;
  }
};
