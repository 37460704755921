import axios from 'utils/axios';
import { createSlice, createSelector } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  GET_INVOICES_AUTOPAY_ROUTE,
  GET_INVOICES_ROUTE,
  GET_INVOICE_AUTOPAY_ROUTE
} from 'utils/endpoints';
import { store } from 'store';
import { setInvoiceList } from 'store';
import { createNotification } from 'common/create-notification';

export const InvoiceSplice = createSlice({
  name: 'InvoiceSplice',
  initialState: {
    invoiceAutoPayWalletList: '',
    invoicesList: [],
    invoiceid: null,
    isLoading: false,
    error: null,
    searchInvoices: null,
    selectedStatus: null,
    startDate: '',
    endDate: ''
  },
  reducers: {
    setStartedDate(state, action) {
      state.startDate = action.payload;
    },
    setEndedDate(state, action) {
      state.endDate = action.payload;
    },
    setSelectedStatus(state, action) {
      state.selectedStatus = action.payload;
    },

    setSearchInvoices(state, action) {
      state.searchInvoices = action.payload;
    },
    setInvoiceList(state, action) {
      state.invoicesList = action.payload;
    },
    setInvoiceId(state, action) {
      state.invoiceid = action.payload;
    },
    setInvoiceAutoPayWalletList(state, action) {
      state.invoiceAutoPayWalletList = action.payload;
    }
  },

  extraReducers: (builder) => {
    // Invoices List Cases
    builder.addCase(fetchInvoicesList.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchInvoicesList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      if (Array.isArray(action.payload)) {
        state.invoicesList = action.payload;
      } else if (!Array.isArray(action.payload)) {
        state.invoicesList = [];
      }
    });

    //Invoice Wallet's
    builder.addCase(fetchInvoiceAutoPayWallet.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchInvoiceAutoPayWallet.fulfilled, (state, action) => {
      if (action.payload) {
        state.invoiceAutoPayWalletList = action.payload;
        state.isLoading = false;
        state.error = null;
      } else {
        console.error(
          'fetched Invoices Wallet List is not array in Invoice-splice'
        );
      }
    });
  }
});

export const fetchInvoicesList = createAsyncThunk(
  'fetchInvoicesList',
  async ({ clientid }, { dispatch }) => {
    try {
      const res = await axios.get(`${GET_INVOICES_ROUTE}/${clientid}`, {
        xApi: true
      });

      if (res.status === 200) {
        return res.data;
      } else {
        console.log('Failed to fetch Invoices List ');
      }
    } catch (error) {
      console.error('Error fetching Invoices data:', error);
      throw error;
    }
  }
);
export const fetchInvoiceAutoPayWallet = createAsyncThunk(
  'fetchInvoiceAutoPayWallet',
  async ({ invoiceid }, { dispatch }) => {
    try {
      const res = await axios.get(`${GET_INVOICE_AUTOPAY_ROUTE}/${invoiceid}`, {
        xApi: true
      });

      if (res.status === 200) {
        return res.data;
      } else {
        console.log('Failed to fetch Invoices AutoPay Wallet List ');
      }
    } catch (error) {
      console.error('Error fetching  Invoices AutoPay Wallet List:', error);
      throw error;
    }
  }
);
export const updateInvoiceAutopay = createAsyncThunk(
  'updateInvoiceAutopayment',
  async ({ data, invoices, invoiceId, clientid }, { dispatch }) => {
    try {
      const res = await axios.post(
        `${GET_INVOICES_AUTOPAY_ROUTE}/${invoiceId}/${clientid}`,
        data,
        {
          xApi: true
        }
      );

      if (res.data) {
        const updatedInvoicesList = invoices.map((invoice) => {
          if (invoice.id === res.data.id) {
            createNotification('success', 'AutoPay Updated Successfully');
            return { ...invoice, ...res.data };
          } else {
            return invoice;
          }
        });
        store.dispatch(setInvoiceList(updatedInvoicesList));
        return res.data;
      } else {
        console.log('Failed to update Auto pay');
      }
    } catch (error) {
      console.error('Error fetching Invoices data:', error);
      throw error;
    }
  }
);

// Selector functions to get individual pieces of state as Input Selectors
const getInvoicesList = (state) => state.InvoiceSplice.invoicesList;
const getSearchInvoices = (state) => state.InvoiceSplice.searchInvoices;
const getSelectedStatus = (state) => state.InvoiceSplice.selectedStatus;
const getStartDate = (state) => state.InvoiceSplice.startDate;
const getEndDate = (state) => state.InvoiceSplice.endDate;

// Memoized state using create selector to avoid reredering and better optimization

export const getFilteredInvoices = createSelector(
  [
    getInvoicesList,
    getSearchInvoices,
    getSelectedStatus,
    getStartDate,
    getEndDate
  ],
  (invoicesList, searchInvoices, selectedStatus, startDate, endDate) => {
    // Create a copy to avoid mutating the original data
    let filteredItems = invoicesList ? [...invoicesList] : [];

    // filtering logic for searchInvoices
    if (searchInvoices) {
      filteredItems = filteredItems.filter((item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchInvoices.toLowerCase())
        )
      );
    }

    // filtering logic for selectedStatus
    if (selectedStatus !== '') {
      filteredItems = filteredItems.filter((item) =>
        typeof selectedStatus === 'string' && selectedStatus.trim() !== ''
          ? item.status_name.toLowerCase() === selectedStatus.toLowerCase()
          : filteredItems
      );
    }
    //  filtering logic based on startDate and endDate

    if (startDate !== '' && endDate !== '') {
      filteredItems = filteredItems.filter((item) => {
        const itemDate = new Date(item.date);
        const _startDate = new Date(startDate);
        const _endDate = new Date(endDate);
        itemDate.setHours(0, 0, 0, 0);
        _startDate.setHours(0, 0, 0, 0);
        _endDate.setHours(0, 0, 0, 0);
        return itemDate >= _startDate && itemDate <= _endDate;
      });
    } else {
      return filteredItems;
    }

    return filteredItems;
  }
);
