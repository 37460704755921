import { store } from 'store';

import axios from 'utils/axios';
import { setClientAccount } from 'store';
import {
  GET_CLIENT_ACCOUNTS_ROUTE,
  GET_CLIENT_FEEDBACK_ROUTE,
  GET_LOGGEDIN_CLIENT_ROUTE
} from 'utils/endpoints';
import { getLoginUser } from './auth';
import { createNotification } from 'common/create-notification';

export const handleClientAccount = async ({ clientid, contactid, data }) => {
  try {
    const res = await axios.get(
      `${GET_CLIENT_ACCOUNTS_ROUTE}/${clientid}/${contactid}`,
      {
        xApi: true
      }
    );
    if (res.status === 200) {
      store.dispatch(setClientAccount({ clientAccount: res.data }));

      return res.data;
    }
  } catch (error) {
    throw error;
  }
};

export const handleClientFeedback = async ({ clientid, contactid, data }) => {
  try {
    const res = await axios.post(
      `${GET_CLIENT_FEEDBACK_ROUTE}/${clientid}/${contactid}`,
      data,
      {
        xApi: true
      }
    );
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    throw error;
  }
};

export const handleClientLogIn = async ({ contactid, data, setIsLoading }) => {
  try {
    const res = await axios.post(
      `${GET_LOGGEDIN_CLIENT_ROUTE}/${contactid}`,
      data,
      {
        xApi: true
      }
    );
    if (res.status === 200) {
      await getLoginUser({ setIsLoading });
      createNotification('success', 'Logged In Successfully');
      return res.data;
    }
  } catch (error) {
    throw error;
  }
};
