import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'utils/axios';
import { GET_TRANSACTION_HISTORY_ROUTE } from 'utils/endpoints';

export const fetchTransactionHistory = createAsyncThunk(
  'fetchTransactionHistory',
  async ({ clientid }, { dispatch }) => {
    try {
      const res = await axios.get(
        `${GET_TRANSACTION_HISTORY_ROUTE}/${clientid}`,
        {
          xApi: true
        }
      );

      if (res.status === 200) {
        return res.data;
      } else {
        console.log('Failed to fetch Trasnsaction History List ');
      }
    } catch (error) {
      console.error('Error fetching Transaction History data:', error);
      throw error;
    }
  }
);
